import Keycloak from 'keycloak-js'
import { isDev } from './helpers'

const isFbSession = window.location.href.includes("session_state")
const isAuthFrame = !!(window.frameElement && window.frameElement.id === "keycloak")

const auth = () => {
    
    if(isDev) console.log("auth")
    const keycloak = new Keycloak('/config.json')

    keycloak.onReady = (...payload) => console.log("onReady", payload)
    // keycloak.onAuthSuccess = (...payload) => console.log("onAuthSuccess", payload)
    
    keycloak.onAuthError = (...payload) =>  console.log("onAuthError", payload)
    keycloak.onAuthLogout = (payload) => window.top.keycloakManager.onAuthLogout(payload)
    keycloak.onAuthSuccess = (payload) => console.log("onAuthSuccess: ", payload)
    keycloak.onAuthError = (payload) => console.log("onAuthError: ", payload)

    keycloak.onAuthRefreshSuccess = (payload) => window.top.keycloakManager.onAuthRefreshSuccess(payload)
    keycloak.onAuthRefreshError = (payload) => window.top.keycloakManager.onAuthRefreshError(payload)
    keycloak.onTokenExpired = (payload) => console.log("onTokenExpired: ", payload) || keycloak.updateToken(20)


    keycloak.init({
        onLoad: 'login-required'
    }).then(authenticated => {
        if(isDev) console.log("authenticated: ", authenticated)
        if(isAuthFrame){
            window.top.keycloakManager.init(keycloak, authenticated)
        } else {
            window.location.href =  "/"
        }
        
    }).catch(e => {
        if(isDev) console.log("login error:", e)
        if(isAuthFrame){
            window.top.keycloakManager.onAuthError({type: 'auth', error: e})
        } else {
            window.location.href =  "/"
        }

    })
}

(isAuthFrame || isFbSession) && auth()