
const userAgent = navigator.userAgent || navigator.vendor || window.opera

export const isMobile = /iPhone|iPod|Android/i.test(userAgent)
export const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent)


export const isAndroid = /android/i.test(userAgent)
export const isIos = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream

export const isIos_and_isNotSafari = isIos && ["YaBrowser", "CriOS", "UCBrowser", "AlohaBrowser", "EdgiOS", "OPT", "FxiOS", "DuckDuckGo", "Chrome"].find(ua_name => userAgent.toLowerCase().includes(ua_name.toLowerCase()))


export const isDev = ['0.0.0.0', 'dev.', 'localhost', 'local.'].some(hostMask => window.location.hostname.includes(hostMask))
export const isLocal = ['0.0.0.0', 'localhost', 'local.'].some(hostMask => window.location.hostname.includes(hostMask))

export const displayBanners = true//isDev

export const getReferrerDomain = () => {

    const referrer = (window !== window.top) ? new URL(document.referrer).hostname : document.location.hostname

    const domain = window.location.ancestorOrigins?.length ? new URL(window.location.ancestorOrigins[0]).hostname : referrer
    
    return domain

}

export const passwordPattern =  "(.{6,256})"
// eslint-disable-next-line no-useless-escape
export const emailPattern =  "[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])*"

export const preventDefault = (e) => {
    e.preventDefault && e.preventDefault() 
    return false
}

const isTouchDeviceFunc = () => {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       (navigator.msMaxTouchPoints > 0))
  }

export const isTouchDevice = isTouchDeviceFunc()

export const durationToTime = (params) => {
    if(!params?.seconds) return undefined
    let { seconds = 0, minutes = 0, hours = 0 } = params || {}
    minutes = minutes + (seconds - (seconds % 60))/ 60
    hours = hours + (minutes - (minutes % 60))/ 60
    seconds = seconds % 60
    const str = `${(minutes > 9 ? "" : "0") + minutes}:${(seconds > 9 ? "" : "0") + seconds}`
    return (hours ? `${(hours > 9 ? "" : "0") + hours}:` : "") + str
  }
  
export const avatarPlaceholder = "/img/ava.png"

// "Mozilla/5.0 (iPhone; CPU iPhone OS 14_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0.1 Mobile/15E148 Safari/604.1",  // iOS + Safari

// "Mozilla/5.0 (iPhone; CPU iPhone OS 14_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.2 Mobile/15E148 DuckDuckGo/7 Safari/605.1.15",
// "Mozilla/5.0 (iPhone; CPU iPhone OS 14_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148",
// "Mozilla/5.0 (iPhone; CPU OS 14_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) FxiOS/30.0 Mobile/15E148 Safari/605.1.15",
// "Mozilla/5.0 (iPhone; CPU iPhone OS 14_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 OPT/2.5.3",
// "Mozilla/5.0 (iPhone; CPU iPhone OS 14_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0 EdgiOS/45.11.11 Mobile/15E148 Safari/605.1.15",
// "Mozilla/5.0 (iPhone; CPU iPhone OS 14_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0 Mobile/15E148 Safari/604.1",
// "Mozilla/5.0 (iPhone; CPU iPhone OS 14_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Version/14.2 Safari/605.1.15 AlohaBrowser/3.0.1",
// "Mozilla/5.0 (iPhone; CPU iPhone OS 14_2 like Mac OS X; en-US) AppleWebKit/537.51.1 (KHTML, like Gecko) Mobile/18B92 UCBrowser/11.3.5.1203 Mobile",
// "Mozilla/5.0 (iPhone; CPU iPhone OS 14_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/87.0.4280.77 Mobile/15E148 Safari/604.1",
// "Mozilla/5.0 (iPhone; CPU iPhone OS 14_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0 YaBrowser/20.11.1.253.10 SA/3 Mobile/15E148 Safari/604.1"
